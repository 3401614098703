.cargo-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 15px;
  border-radius: $border-radius;
  background-color: $primary-color;
  color: $color-lightest;
  margin: 10px 10px 10px 0;
  @extend .cargo-font-size-16-20;
  transition: transitionFunc(background-color);
  @include breakpoint-max(sm) {
    padding-top: 10px;
  }
  &:hover {
    color: $color-lightest;
    background-color: $secondary-color;
  }

  &--content {
    padding-top: 8px;
    padding-bottom: 7px;
    @extend .cargo-font-size-14;
    @extend .cargo-font-weight-700;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }

  &--icon-right {
  }

  &--only-icon {
    margin-right: 6px;
    padding: 0;
    width: 36px;
    height: 36px;
    @extend .cargo-font-size-14-18;
    &:last-of-type {
      margin-right: 0;
    }
  }

  .icon-margin-left {
    margin-left: 10px;
  }
}
