.cargo-tile {
  margin: 0 6px 12px;
  padding: 14px 0 0;
  width: 100%;
  border: solid 1px $border-color;
  box-shadow: $box-shadow;
  position: relative;
  @include breakpoint-min(md) {
    width: calc(50% - 12px);
  }
  @include breakpoint-min(sl) {
    width: calc(33.333% - 12px);
  }
  @include breakpoint-min(xl) {
    width: calc(25% - 12px);
  }
  @include breakpoint-min(xxxl) {
    width: calc(20% - 12px);
  }
  .video {
    &::before {
      content: '';
      height: 20%;
      background-size: contain;
      width: 20%;
      left: 40%;
      top: 20%;
      filter: invert(1);
      background-repeat: no-repeat;
      position: absolute;
      display: block;
      background-image: url('../../assets/play.png');
    }
  }
  &__photo {
    display: block;
    overflow: hidden;
    @include breakpoint-min(md) {
      height: fluidSize(115, 195);
    }
    img {
      width: 90%;
      display: block;
      margin: 0 auto;
      height: 100%;
      object-fit: none;
      @include breakpoint-min(xl) {
        height: 100%;
      }
    }
  }

  &__text {
    padding: 0 15px;
    min-height: 100px;
    margin-bottom: 40px;
  }

  &__container {
    padding-top: 15px;
    p  {
      margin: 0;
      line-height: 1.44;
      height: 5.76em;
      word-break: break-word;
      overflow: hidden;
    }
  }

  &__buttons {
    position: absolute;
    right: 14px;
    bottom: 0;

    .cargo-button--only-icon {
      margin-bottom: 13px;
    }
  }
  &__single-button {
    display: flex;
    justify-content: center;
    border-top: 1px solid #e5e5e5;
    color: #042887;
    font-size: 18px;
    a {
      display: flex;
      padding: 10px;
      flex: 1;
      justify-content: center;
      span {
        padding: 0 0 0 15px;
      }
    }
    .icon {
      display: flex;
      align-items: center;
      &:before {
        color: #042887;
      }
    }
  }
}
