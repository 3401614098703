.cargo-hamburger {
  position: relative;
  width: 16px;
  height: 14px;
  border: none;
  background-color: inherit;
  cursor: pointer;
  z-index: 0;
  @include breakpoint-min(sl) {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
  &.is-active {
    .hamburger__line {
      &:nth-of-type(1) {
        transform: rotate(-45deg);
        top: 4px;
      }

      &:nth-of-type(2) {
        width: 0;
      }

      &:nth-of-type(3) {
        transform: rotate(45deg);
        top: 4px;
      }
    }
  }
  &__line {
    display: block;
    position: absolute;
    right: 0;
    height: 2px;
    background-color: $color-dark;
    transition: transitionFunc(transform, top);
    &:nth-of-type(1),
    &:nth-of-type(3) {
      width: 100%;
    }

    &:nth-of-type(1) {
      top: 0;
    }

    &:nth-of-type(2) {
      top: 6px;
      width: 80%;
    }

    &:nth-of-type(3) {
      top: 12px;
      width: 100%;
    }

  }
}