.cargo-search {
  &.is-active {
    .cargo-search__container {
      display: block;
    }
  }

  &__container {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    padding: 20px;
    background-color: $color-light;
    @include breakpoint-min(md) {
      left: auto;
    }
    @include breakpoint-min(sl) {
      display: block;
      position: static;
      transform: translateY(0);
      padding: 0;
    }
  }

  &__input-container {
    position: relative;
    margin: 0 auto;
    max-width: 300px;
  }

  &__input {
    padding: 7px 55px 7px 10px;
    width: 100%;
    border: none;
    border-bottom: 1px solid $color-dark;
    outline: none;
    box-shadow: none;
    color: $color-darker;
    background-color: transparent;
    transition: transitionFunc(border-bottom);
    &:focus {
      border-color: $primary-color;
      & + .cargo-search__button {
        color: $primary-color;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-darker;
    }

    &:-ms-input-placeholder {
      color: $color-darker;
    }

    &::placeholder {
      color: $color-darker;
    }
  }

  &__button {
    position: absolute;
    bottom: 3px;
    right: 4px;
    border: none;
    outline: none;
    box-shadow: none;
    color: $color-dark;
    transition: transitionFunc(color);
    background: transparent;
  }

  &__icon {
    font-size: fluidSize(15, 32);
    color: inherit;
  }

  &__button-mobile {
    display: block;
    border: none;
    font-size: 26px;
    transform: translateY(3px);
    background-color: transparent;
    @include breakpoint-min(sl) {
      display: none;
    }
  }

  &__icon-mobile {
    font-size: inherit;
    color: inherit;
  }
}
