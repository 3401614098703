.cargo-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  &__button {
    line-height: 0;
    color: $color-darker;
    &--prev {

    }

    &--last {

    }
  }

  &__links {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__link {
    a {
      display: inline-block;
      margin: 0;
      padding: 0 6px;
      line-height: 1;
      box-sizing: border-box;
      color: $color-dark;
      transition: transitionFunc(background-color, color);
      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }

    &--disable {
      margin-left: 15px;
      margin-right: 5px;
      pointer-events: none;
    }

    &--current a {
      color: $primary-color;
      text-decoration: underline;
    }
  }
}