$primary-color: #042887;
$secondary-color: #194a8d;
$color-lightest: #fff;
$color-light: #f2f2f2;
$color-dark: #6d6e6e;
$color-darker: #393c47;
$color-blue: #002389;

$border-color: #e5e5e5;
$color-article: #454765;

$transition-duration: 0.25s linear;

$box-shadow: 0 0 20px 0 rgba(187, 191, 206, 0.36);
$box-shadow-box: 0 1px 3px 0 rgba(0, 0, 0, 0.2);;

$border-radius: 2px;

$breakpoints: (
  xs: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  sl: 1040px,
  xl: 1280px,
  xxl: 1400px,
  xxxl: 1600px
) !default;