// ==========================================================================
// Scaling Fuctnions
// ==========================================================================
$scale-ratio: 1.25 !default;
$scale-unit: 16 !default;

@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

@function rem($value, $scale: $scale-unit) {
  $root: $scale;
  $val: parseInt($value);
  $return: ();

  @if unit($value) == "px" {
    $return: append($return, ($val / $root + rem));
  } @else {
    $return: append($return, ($val * $root + px));
  }

  @return $return;
}

@function em($value, $scale: $scale-unit) {
  $root: $scale;
  $val: parseInt($value);
  $return: ();

  @if unit($value) == "px" {
    $return: append($return, ($val / $root + em));
  } @else {
    $return: append($return, ($val * $root + px));
  }

  @return $return;
}

@function line-scale($value) {
  @return $value * $scale-ratio;
}

@function fluidSize($min-size, $max-size) {
  $max-viewport: 1920;
  $min-viewport: 320;

  @return calc(
    #{$min-size}px + #{parseInt($max-size - $min-size)} * ((
            100vw - #{$min-viewport}px
          ) / #{parseInt($max-viewport - $min-viewport)})
  );
}

@function vw($target) {
  $vw-context: (1920 * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
}
