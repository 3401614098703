.cargo-content-article {
  color: $color-article;
  &__container {
    overflow-wrap: break-word;
    padding: fluidSize(30, 60) fluidSize(20, 50) fluidSize(40, 70);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.2em;
    @extend .cargo-font-weight-600;
  }

  img {
    margin: 2.35em 0;
  }

  p {
    margin-bottom: 1.2em;
  }
}