.cargo-content {
  margin: fluidSize(30, 80) auto fluidSize(50, 130);
  width: 100%;
  max-width: 1363px;
  box-shadow: $box-shadow-box;

  &__buttons {
    margin-top: 40px;
    @include breakpoint-min(lg) {
      position: absolute;
      left: fluidSize(20, 50);
      bottom: fluidSize(30, 70);
      margin-top: 0;
    }

    &--photo {
      @include breakpoint-min(lg) {
        left: fluidSize(20, 157);
      }
    }

    .cargo-content__button {
      &:first-of-type:not(:only-of-type) {
        margin-right: 28px;
      }
    }
  }
}