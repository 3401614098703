.cargo-tiles {
  margin-bottom: 18px;
  padding-bottom: 18px;
  border-bottom: solid 1px $border-color;
  &:first-of-type {
    .cargo-tiles-header {
      margin-top: fluidSize(50, 100);
      & + .cargo-tiles__container {
        margin-top: 0;
      }
    }

    .cargo-tiles__container {
      margin-top: fluidSize(50, 100);
    }
  }

  &:last-of-type {
    margin-bottom: fluidSize(40, 75);
    padding-bottom: 0;
    border-bottom: none;
  }

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fluidSize(35, 45);
      height: fluidSize(35, 45);
      color: $color-lightest;
      background-color: $primary-color;
    }
  }

  &__title {
    margin: 0;
    padding-left: fluidSize(20, 37);
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
  }

  &__buttons {
    margin-top: 6px;
    text-align: right;
  }

  .cargo-tiles__subcontainer {
    margin-top: 100px;
    .cargo-tiles__container {
      margin-top: 0;
    }
  }
}
