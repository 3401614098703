.cargo-content-videos {
  &__container {
    @include breakpoint-min(lg) {
      display: flex;
    }
  }
  &__video {
    @include breakpoint-min(lg) {
      width: 66%;
    }

    video {
      display: block;
      width: 100%;
    }
  }

  &__desc {
    overflow-wrap: break-word;
    padding: fluidSize(20, 87) fluidSize(20, 36) 30px fluidSize(20, 50);
    @include breakpoint-min(lg) {
      position: relative;
      padding-bottom: fluidSize(100, 120);
      width: 34%;
    }
  }
  .iframe {
    @include breakpoint-max(sm) {
      width: 100%;
      height: 250px;
    }
    width: 600px;
    height: 500px;
  }
}
