.cargo-content-photo {
  &__container {
    @include breakpoint-min(lg) {
      display: flex;
    }
    .pdf-viewer {
      @include breakpoint-min(md) {
        height: 588px;
        width: 588px;
      }
    }
  }

  &__photo {
    @include breakpoint-min(lg) {
      width: 43%;
    }

    img {
      width: 100%;
    }

    .document-icon {
      height: 100%;
      padding:20px;
      width: 100%;

      div{
        height: 100%;
        width: 100%;
        background-size: contain;
        background-image: url(../../document.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  &__desc {
    overflow-wrap: break-word;
    padding: fluidSize(20, 60) fluidSize(20, 50) 30px fluidSize(20, 157);
    @include breakpoint-min(lg) {
      position: relative;
      padding-bottom: fluidSize(100, 120);
      width: 57%;
    }
  }
}
