// BEGIN FONT SIZE
.cargo-font-size-18-25 {
  font-size: fluidSize(18, 25);
}

.cargo-font-size-16-20 {
  font-size: fluidSize(16, 20);
}

.cargo-font-size-14-18 {
  font-size: fluidSize(14, 18);
}

.cargo-font-size-14-16 {
  font-size: fluidSize(14, 16);
}

.cargo-font-size-14 {
  font-size: 14px;
}
// END FONT SIZE

// BEGIN FONT WEIGHT
.cargo-font-weight-900 {
  font-weight: 900;
}

.cargo-font-weight-700 {
  font-weight: 700;
}

.cargo-font-weight-600 {
  font-weight: 600;
}

.cargo-font-weight-400 {
  font-weight: 400;
}
// END FONT WEIGHT