@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?i3mtdz');
  src: url('fonts/icomoon.eot?i3mtdz#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?i3mtdz') format('truetype'),
    url('fonts/icomoon.woff?i3mtdz') format('woff'),
    url('fonts/icomoon.svg?i3mtdz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-forward:before {
  content: '\e90a';
}
.icon-Group-103:before {
  content: '\e900';
  color: #fff;
}
.icon-Group-162:before {
  content: '\e901';
  color: #fff;
}
.icon-Group-163:before {
  content: '\e902';
  color: #fff;
}
.icon-icon-filmy:before {
  content: '\e903';
  color: #fff;
}
.icon-keyboard_arrow_left-24px:before {
  content: '\e904';
  color: #002389;
}
.icon-Path-2:before {
  content: '\e905';
  color: #6d6e6e;
}
.icon-Path-15:before {
  content: '\e906';
  color: #fff;
}
.icon-Path-45:before {
  content: '\e907';
  color: #fff;
}
.icon-Path-55:before {
  content: '\e908';
  color: #fff;
}
.icon-Path-64:before {
  content: '\e909';
  color: #fff;
}
.icon-arrow-drop-down:before {
  content: '\e91d';
}
.icon-dashboard:before {
  content: '\e91e';
}
.icon-exit-to-app:before {
  content: '\e91f';
}
.icon-file-copy:before {
  content: '\e920';
}
.icon-search:before {
  content: '\e921';
}

.rotate-90 {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.rotate-plus-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.icon-arrow:before {
  content: '\e907';
}
