* {
  outline: none;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

.cargo {
  font-family: 'Muli', sans-serif;
  @extend .cargo-font-size-14-18;
  .ant-layout {
    background-color: transparent;
  }

  &__main {
    margin: 0 fluidSize(20, 140);
    min-height: 75vh;
  }
  .page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }
  .home-title {
    font-size: 20x;
    display: flex;
    justify-content: center;
    color: #6d6e6e;
  }
}
