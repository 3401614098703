.cargo-menu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: fluidSize(15, 25) fluidSize(15, 63) fluidSize(15, 25)
    fluidSize(15, 140);
  background-color: $color-light;
  @include breakpoint-min(sl) {
    align-items: flex-end;
  }
  &.is-active {
    .cargo-menu__list {
      opacity: 1;
      pointer-events: auto;
      z-index: 1;
    }

    .cargo-hamburger {
      &__line {
        &:nth-of-type(1) {
          transform: rotate(-45deg);
          top: 4px;
        }

        &:nth-of-type(2) {
          width: 0;
        }

        &:nth-of-type(3) {
          transform: rotate(45deg);
          top: 4px;
        }
      }
    }
  }

  &__logo {
    margin: 0;
    img {
      height: fluidSize(30, 51);
    }
  }

  &__search {
    margin-right: 20px;
    margin-left: auto;
    @include breakpoint-min(sl) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &__list {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    margin: 0;
    padding: 50px 0 20px;
    background-color: inherit;
    list-style-type: none;
    opacity: 0;
    pointer-events: none;
    transition: transitionFunc(opacity);
    @include breakpoint-min(sl) {
      position: static;
      display: flex;
      justify-content: space-between;
      transform: translateY(0);
      padding: 0;
      opacity: 1;
      pointer-events: auto;
    }
  }

  &-item {
    position: relative;
    margin-bottom: 30px;
    padding: fluidSize(28, 41) fluidSize(10, 22) 0;
    text-align: center;
    @include breakpoint-min(sl) {
      margin-bottom: 0;
    }
    &--lang {
      @include breakpoint-max(sl) {
        padding: 0;
        margin: 0;
      }

      .cargo-menu-item__text {
        position: relative;
        display: none;
        @include breakpoint-min(sl) {
          display: block;
        }
      }

      .cargo-menu-item__icon {
        font-size: 22px;
        top: 50%;
        right: -18px;
        transform: translateY(-50%);
        transition: transitionFunc(transform);
        color: inherit;
        &.is-rotate {
          transform: translateY(-50%) rotate(-180deg);
        }
      }
    }

    &__icon {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      font-size: fluidSize(24, 34);
      color: $primary-color;
    }

    &__text {
      border: none;
      background-color: transparent;
      color: $color-dark;
      cursor: pointer;
      transition: transitionFunc(color);
      &:hover {
        color: $primary-color;
      }
    }
  }

  &__langs {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 15px 0 0;
    border-top: 1px solid $color-dark;
    text-align: center;
    list-style-type: none;
    pointer-events: none;
    @include breakpoint-min(sl) {
      display: none;
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      transform: translateY(100%);
      padding: 0;
      border-top: none;
    }
    &.is-visible {
      display: block;
      pointer-events: auto;
    }
  }

  &__lang {
    border-bottom: 1px solid $color-light;
    &:last-of-type {
      border-bottom: none;
    }

    button {
      display: inline-block;
      padding: 10px;
      transition: transitionFunc(background-color);
      border: none;
      cursor: pointer;
      @include breakpoint-min(sl) {
        width: 100%;
        background-color: $primary-color;
        color: $color-lightest;
      }
      &:hover {
        color: $color-lightest;
        @include breakpoint-min(sl) {
          background-color: $secondary-color;
        }
      }
    }
  }
}
