.cargo-footer {
  background-color: $secondary-color;
  color: $color-lightest;
  &__container {
    padding: fluidSize(30, 46) fluidSize(20, 212);
  }

  &__text {
    margin: 0 0 25px;
    &:last-of-type {
      margin-bottom: 0;
    }
    a {
      color: $color-lightest;
      &:hover {
        color: $color-lightest;
      }
    }
  }
}