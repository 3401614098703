.cargo-breadcrumbs {
  margin-top: fluidSize(20, 35);
  &__links {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__link {
    position: relative;
    margin-right: fluidSize(15, 37);
    &:last-of-type {
      margin-right: 0;
      @extend .cargo-font-weight-700;
      color: $color-blue;
      &:hover {
        color: $secondary-color
      }
    }

    a {
      color: inherit;
    }
  }

  &__icon {
    transform: rotate(180deg);
    margin-right: fluidSize(5, 17);
  }
}